.dhx_scale_holder_now {
  background-color: #f9f87119;
}

.dhx_scale_holder.weekend {
  background-color: var(--COLOR_LIGHT);
}

.dhx_calendar_title {
  letter-spacing: 0.2px;
  font-family: var(--SANS_FONT_FAMILY);
  font-weight: 700;
}

.dhx_calendar_caption {
  letter-spacing: 0.2px;
  font-family: var(--SANS_FONT_FAMILY);
  font-weight: 300;
  font-size: 11px;
}
.dhx_cal_event_clear {
  color: var(--COLOR_LIGHT);
}
.dhx_cal_event_clear b {
  padding-left: 8px;
}
