.react-datepicker {
  font-family: 'Inter', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.875rem;
  color: #302d43;
  border: 1px solid #e8e7ed;
}

.react-datepicker-popper {
  z-index: 99;
}

.react-datepicker__header {
  background-color: #fff5ff;
  border-bottom: 1px solid #e8e7ed;
  padding: 0 16px;
}

.react-datepicker__current-month {
  padding: 16px 0;
}

.react-datepicker__triangle {
  border-bottom-color: #fff5ff !important;
}

.react-datepicker__triangle::before {
  border-bottom-color: #e8e7ed !important;
}

.react-datepicker__week-number {
  color: #302d43;
  font-weight: 600;
  line-height: 2;
}

.react-datepicker__navigation {
  top: 20px;
}

.react-datepicker__navigation--previous {
  border-right-color: #8c7388;
  left: 16px;
}

.react-datepicker__navigation--next {
  border-left-color: #8c7388;
  right: 16px;
}

.react-datepicker__day--keyboard-selected {
  background-color: #3717cb;
}

.react-datepicker select {
  border: 1px solid #e8e7ed;
  border-radius: 4px;
  background-color: #fff;
  padding: 4px 8px;
  margin-bottom: 12px;
  font-family: 'Inter', 'Helvetica', 'Arial', sans-serif;
}

.react-datepicker__close-icon {
  padding: 0 2px 0 0;
}
