.toolbarCustomButton > u {
  border-radius: 2px;
  margin-right: 1px;
  margin-left: 1px;
  padding: 0;
  border: 0;
}

.toolbarCustomButton > u > button {
  font-weight: 400;
  font-size: 12px;
  padding: 6px;
  cursor: pointer;
  letter-spacing: 0.5px;
  font-family: var(--SANS_FONT_FAMILY, 'Inter', sans-serif);
  color: var(--COLOR_BLACK, #141414);
  border: 0;
  border-radius: 2px;
  transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.toolbarCustomButton--small > u > button {
  height: 15px;
}
.toolbarCustomButton > u > button:disabled {
  background-color: var(--COLOR_LIGHT);
  border-color: var(--COLOR-LIGHT, #ccc);
  color: var(--COLOR_DISABLED, #ccc);
  cursor: not-allowed;
}
.toolbarCustomButton--primary > u > button {
  border: 1px solid var(--COLOR_PRIMARY, blue);
  background-color: var(--COLOR_PRIMARY, blue);
  color: var(--COLOR_WHITE, #fff);
}

.toolbarCustomButton > u > .icon {
  font-weight: 400;
  font-size: 22px;
}

.largeText {
  font-size: 16px;
  font-weight: 900px;
}
.TWToolFilter_Button,
.TSToolFilter_Button {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiM2MjYzNjciIHN0cm9rZS13aWR0aD0iMSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLWZpbHRlciI+PHBvbHlnb24gcG9pbnRzPSIyMiAzIDIgMyAxMCAxMi40NiAxMCAxOSAxNCAyMSAxNCAxMi40NiAyMiAzIj48L3BvbHlnb24+PC9zdmc+');
}

.TWToolFilter_Button_Active,
.TSToolFilter_Button_Active {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiM2MjYzNjciIHN0cm9rZS13aWR0aD0iMSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLWZpbHRlciI+PHBvbHlnb24gcG9pbnRzPSIyMiAzIDIgMyAxMCAxMi40NiAxMCAxOSAxNCAyMSAxNCAxMi40NiAyMiAzIj48L3BvbHlnb24+PC9zdmc+');
  background-color: #e8e7ed;
}
.redText {
  color: var(--COLOR_ERROR, #ff3333) !important;
  font-weight: 600;
  text-decoration: wavy;
}

/*The main treegrid table */
.TWMain {
  font-family: 'Inter', 'Helvetica', 'Arial', sans-serif;
  color: var(--COLOR_BLACK, #141414) !important;
}

.TWDataRow td,
.TWDataRow td div {
  font-size: 13px;
  color: var(--COLOR_BLACK, #141414);
}

.TWHeaderText {
  font-weight: 600;
  font-size: 14px;
}

.TWHeaderRow {
  min-height: 160px !important;
}

.TWHeaderRow td {
  background: var(--COLOR-LIGHT, #f6f5ff);
  color: var(--COLOR_BLACK, #141414);
}
.highlightedTreegridRow {
  background-color: var(--COLOR_LIGHT, #f6f5ff);
}

.TWFastPanel {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHZpZXdCb3g9IjAgMCA2NCA2NCI+DQo8ZyBmaWxsPSIjOTk5Ij4NCiAgIDxwb2x5Z29uIHBvaW50cz0iNDQsMTIgMzIsMCAyMCwxMiIvPg0KICAgPHBvbHlnb24gcG9pbnRzPSI1Miw0NCA2NCwzMiA1MiwyMCIvPg0KICAgPHBvbHlnb24gcG9pbnRzPSIxMiwyMCAwLDMyIDEyLDQ0Ii8+DQogICA8cG9seWdvbiBwb2ludHM9IjIwLDUyIDMyLDY0IDQ0LDUyIi8+DQo8L2c+DQo8L3N2Zz4=),
    url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHZpZXdCb3g9IjAgMCA2NCA2NCI+DQo8Y2lyY2xlIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzk5OSIgc3Ryb2tlLXdpZHRoPSI0IiBjeD0iMzAiIGN5PSIzMCIgcj0iMjAiLz4NCjwvc3ZnPg==),
    url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHZpZXdCb3g9IjAgMCA2NCA2NCI+DQo8Y2lyY2xlIGZpbGw9IndoaXRlIiBzdHJva2U9IiMzRDdGQzIiIHN0cm9rZS13aWR0aD0iNCIgY3g9IjMwIiBjeT0iMzAiIHI9IjIwIi8+DQo8Y2lyY2xlIGZpbGw9IiM0QjhGQ0QiIGN4PSIzMCIgY3k9IjMwIiByPSIxMiIvPg0KPC9zdmc+),
    url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmMzMiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLXRyYXNoLTIiPjxwb2x5bGluZSBwb2ludHM9IjMgNiA1IDYgMjEgNiI+PC9wb2x5bGluZT48cGF0aCBkPSJNMTkgNnYxNGEyIDIgMCAwIDEtMiAySDdhMiAyIDAgMCAxLTItMlY2bTMgMFY0YTIgMiAwIDAgMSAyLTJoNGEyIDIgMCAwIDEgMiAydjIiPjwvcGF0aD48bGluZSB4MT0iMTAiIHkxPSIxMSIgeDI9IjEwIiB5Mj0iMTciPjwvbGluZT48bGluZSB4MT0iMTQiIHkxPSIxMSIgeDI9IjE0IiB5Mj0iMTciPjwvbGluZT48L3N2Zz4=),
    url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHZpZXdCb3g9IjAgMCA2NCA2NCI+DQo8cGF0aCBmaWxsPSIjQThEM0I4IiBkPSJNMzAsOEMxNy44LDgsOCwxNy44LDgsMzBzOS44LDIyLDIyLDIyczIyLTkuOCwyMi0yMlM0Mi4yLDgsMzAsOHogTTQ0LDMySDMydjEyaC00VjMySDE2di00aDEyVjE2aDR2MTJoMTJWMzJ6Ii8+DQo8L3N2Zz4=);
}

.group-header {
  font-size: 0.95rem !important;
  font-weight: 600;
  color: var(--COLOR_BLACK, #141414);
  /* color: var(--COLOR_PRIMARY, #4b4cd7); */
  margin: 0;
  padding: 20px 0;
}

.TWCellBase {
  border-right: 0;
}

.TWGanttHeader1,
.TWGanttHeader2,
.TWGanttHeader3 {
  background-color: var(--COLOR-LIGHT, #f6f5ff);
}

.openButton {
  max-height: unset !important;
  overflow: unset !important;
  min-width: 100%;
  cursor: pointer;
}

.rowNumber {
  background-color: var(--COLOR_WHITE, #fff);
  font-weight: 400;
}

.ganttDayHeader {
  font-size: 11.5px;
}

.toolbarAddTaskButtonRoot {
  display: flex;
  border-color: var(--COLOR_PRIMARY, #1675b3);
  border: 1px solid var(--COLOR_PRIMARY, #1675b3);
  border-radius: 4px;
}

.toolbarAddTaskButtonRoot .MuiButton-root {
  border-radius: 0;
  text-transform: none;
  box-shadow: none;
  position: relative;
}

.toolbarAddTaskButtonRoot .MuiButton-root #addTaskButtonClickArea {
  background: transparent;
  position: absolute;
  inset: 0;
  z-index: 9;
}

.toolbarAddTaskButtonRoot .MuiButton-root .MuiButton-label {
  font-size: 14px;
  font-weight: 600;
}

.toolbarAddTaskButtonRoot input {
  border: 0;
  outline: 0;
  padding: 4px;
  display: inline-block;
  margin-left: 12px;
  margin-right: 4px;
  font-size: 14px;
}

.toolbarAddTaskButtonRoot input::-webkit-outer-spin-button,
.toolbarAddTaskButtonRoot input::-webkit-inner-spin-button {
  opacity: 1;
}
